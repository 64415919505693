<template>
  <b-modal
    v-model="modalShow"
    class="confirmModal"
    ref="confirmModal"
    :title="'สิทธิ์ในการเข้าถึงเมนูของ ' + name"
    hide-footer
    no-close-on-backdrop
    centered
    no-body
    @hidden="handleCloseModal"
  >
    <div>
      <ol>
        <li v-for="role of roleList" :key="role.id">
          {{ role.name }}
          <ol>
            <li
              v-for="roles of role.subCondition"
              :key="role.id + '-' + roles.id"
            >
              {{ roles.name }}
            </li>
          </ol>
        </li>
      </ol>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: { roleID: { required: true } },
  data() {
    return {
      modalShow: false,
      roleList: [],
      name: "",
    };
  },
  mounted() {},
  methods: {
    async getRoleID() {
      const { data } = await this.$Axios(
        `${process.env.VUE_APP_API}/Role/${this.roleID}`
      );
      const filter = data.detail.roleSelect.filter((el) => {
        el.subCondition = el.subCondition.filter(
          (el) => el.isCheck == 1 && el.display == 1
        );
        return el.display == 1 && el.isCheck == 1;
      });

      this.name = data.detail.name;
      this.roleList = filter;
    },
    show() {
      this.getRoleID();
      this.modalShow = true;
    },
    close() {
      this.modalShow = false;
    },
    handleOkModal() {
      this.$emit("handler", true);
      this.modalShow = false;
    },
    handleCloseModal() {
      this.$emit("handler", false);
      this.modalShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.confirmModal {
  width: auto;
}
.modal-header {
  border: 0 !important;
  padding: 0;
}
.text-header {
  font-size: 18px;
}
.text-text {
  color: #4a4a4a;
  margin-bottom: 0;
  font-size: 1.25rem;
  word-wrap: break-word !important;
}
.text-sub {
  color: #afce38;
  font-size: 1.25rem;
  word-wrap: break-word !important;
}
.modal-body {
  margin: auto;
  text-align: center;
}
::v-deep .btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.icon-alert {
  font-size: 50px;
  margin: 10px 0;
  color: var(--primary-color);
}
ol {
  counter-reset: item;
}
li {
  display: block;
}
li:before {
  content: counters(item, ".") ".";
  counter-increment: item;
}
</style>
